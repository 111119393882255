import React from "react";
import styled from "styled-components";
import "./App.css";

const Footer = styled.p`
  font-size: 10px;
`;

const App: React.FC = () => {
  return (
    <div className="App">
      <header className="App-header">
        <p>Coming soon...</p>
      </header>
      <Footer>Jonathan Ding 2019</Footer>
    </div>
  );
};

export default App;
